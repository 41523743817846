//@import '../node_modules/bootstrap/scss/_variables';

html, body {
  margin: 0;
  padding-top:80px;
  height: 100%;
  background-color: #f5f7fa;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #1f2430;
  overflow-x: hidden;
}

@media (min-width: 1440px) {
    .container {
        width: 1300px;
  }
}
/*body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }*/
.flag-image{
	height:16px;
	vertical-align:middle;
	cursor:pointer;
	margin:10px;
	}
	.flag-image.selected{
	  transform: scale(1.3);
	}
	.topbar{
		background-color: #ededed;
		color:black;
		padding: 5px;
		text-align: center;
		height:40px;
		a{
		  color:#dc3545;
		}
	  }
	  .price-row{
		max-height:50px;
		display: flex;
		justify-content: flex-end;
	  }
@media only screen and (max-width: 800px) {
	.topbar{
		height:fit-content;
	}
	.table-bordered{
		border:none !important;
	}
	#no-more-tables {
		table {
			display: block;
		}
		thead {
			display: block;
			tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
		}
		tbody {
			display: block;
		}
		th {
			display: block;
		}
		td {
			display: block;
			border: none;
			position: relative;
			padding-left: 30%;
			white-space: normal;
			text-align: left;
			min-height:2.5em;
			&:before {
				position: absolute;
				top: 6px;
				left: 6px;
				width: 50%;
				padding-right: 10px;
				white-space: nowrap;
				text-align: left;
				font-weight: bold;
				content: attr(data-title);
			}
		}
		.table>:not(:first-child) {
			border-top: none !important;
		}
		tr {
			display: block;
			border: 1px solid #ccc;
			margin-bottom: 30px;
			border-radius: 10px;
			padding:10px;
		}
		.add-button {
			width: 100%;
			margin-top: 10px;
			margin-bottom: 20px;
		}
		.header-row {
			margin-top: 20px !important;
			margin-bottom: 30px !important;
		}
	}
}
.input-group-text {
}
.flag-icon-list{
  margin-left:10px;
  cursor:pointer;
  opacity: 0.4;
    filter: alpha(opacity=70); /* msie */
}

.flag-icon-list.selected{
  opacity: 1;
  filter: none;
}
@import "~@ng-select/ng-select/themes/default.theme.css";
//
//  .bootstrap-dark{
//    @media (min-width: 1200px){
//      .bootstrap .container, .bootstrap .container-lg, .bootstrap .container-md, .bootstrap .container-sm, .bootstrap .container-xl {
//        max-width: 1300px ;
//    }
//    }
//    ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//      background-color: rgb(2, 2, 2);
//      color: #ffffff;
//    }
//
//      .ng-dropdown-panel .scroll-host {
//        background: #0f0f0f!important;
//        border: 1px solid rgb(29, 28, 28) !important;
//      }
//      .ng-select.custom-ng  ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items {
//        box-sizing: border-box;
//        display: block;
//        height: auto;
//        max-height: 240px;
//        overflow-y: auto;
//        background: rgb(10, 10, 10);
//
//        border: 1px solid #3a3a3a;
//
//      }
//      .ng-select.custom-ng  ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
//        padding: 10px 10px;
//      }
//      .ng-select.custom-ng  ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option-marked {
//        background-color: #ff008c;
//        color: white;
//      }
//      .ng-select.ng-invalid.ng-touched .ng-select-container {
//        border-color: #dc3545;
//        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
//      }
//      .ng-select.ng-select-opened>.ng-select-container {
//        background: rgb(7, 7, 7);
//        border-color: #b3b3b3 #ccc #d9d9d9;
//    }
//    .ng-select .ng-select-container .ng-value-container .ng-input>input {color:white}
//    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
//      background-color: #373838;
//      color: white;
//  }
//  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
//    color: #333;
//    background-color: #a7ccf1;
//}
//.ng-select .ng-select-container {
//  color: rgb(250, 249, 249);
//  background-color: black;
//}
//.font-weight-bold{
//  color:white;
//}
// .bg-light,  .bg-themed-inverted {
//  background-color: #0f0f0f!important;
//
//}
//.popover-header {
//  background-color: #2c2b2b !important;
//  border-bottom: 1px solid #666666;
//  color:white;
//}
// .popover-body {
//  color: white;
//  background-color: #0f0f0f;
//}
//select{
//  background-color: black !important;
//  color:white;
//}
//.account-row{
//  background-color:#444444!important;
//  color:white;
//  }
//  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
//    color: white;
//    background-color: #444444;
//
//}
//.table-striped>tbody>tr:nth-of-type(odd) {
//  --bs-table-accent-bg: var(--bs-table-striped-bg);
//  color: #d4d7d9;
//}
//.ngx-pagination a, .ngx-pagination button {
//  color: #d4d7d9;
//}
//.ngx-pagination a, .ngx-pagination button:hover {
//  background-color:#444444!important;
//  ;
//}
//  }

// form
//.form-container {
//  margin:auto;
//
//  .row {
//    margin:20px;
//  }
//}
//
//.form-group.required label:after {
//  content:"*";
//  color:red;
//}
//
//@media only screen and (max-width: 800px) {
//  .nav-tabs {
//    padding-left:2px;
//    padding-right:2px;
//  }
//  .nav-tabs li {
//    display:block !important;
//    width:100%;
//    margin:0px;
//  }
//  .nav-tabs li.active {
//    border-bottom:1px solid #ddd!important;
//    margin: 0px;
//  }
//}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* NU STERGE CA FUTI TOT */

.bootstrap-dark{
	.card-active{
		background-color: #343a40 !important;
	}
	.ngx-pagination a:hover, .ngx-pagination button:hover {
		background: #5d6061;
	}
	.ngx-pagination a, .ngx-pagination button {
		color: #f9fcff;
	}
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		background-color: #000000;
		color: rgba(255, 255, 255, 0.87);
		padding: 8px 10px;
	}
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
		background-color: #4e4d4d;
		color: #fff;
	}
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
		color: #fff;
		background-color: #4e4d4d;
	}
	.ng-select.ng-select-opened > .ng-select-container {
		background: black;
		border-color: #b3b3b3 #ccc #d9d9d9;
		color:white;
	}
	.ng-select .ng-select-container {
		color: white;
		background-color: black;
		border: 1px solid #ccc;
	}
	::-webkit-calendar-picker-indicator {
		filter: invert(1);
	}

	.navbar{
		background-color: grey;
	}
	.bg-light {
		background-color: #000000!important;
	}
	.hamburger-menu  {
	span{
		background-color: white !important;
		&:before {background-color: white !important;}
		&:after {background-color: white !important;}
	}
	}
	.table-striped > tbody > tr:nth-of-type(odd) > * {
		color: #f4f8fb;
	}
	.table-striped > tbody > tr:hover > * {
		color: #89c2ee;
	}
}
a{
	text-decoration: none !important;
}
.form-check-input:checked {
	background-color: #0d6efd;
	border-color: #0d6efd;
}
.ng-select.is-invalid .ng-select-container{
	border-color:red !important;
}
.btn-group {
	.btn-sm{
		font-size:12px;
		padding:1px 10px;
	}
}
.success-icon{
	font-size: 5em !important;
	color: green
   }

   .info-icon{
	font-size: 5em !important;
	color: #3682ff
   }

   .error-icon{
	font-size: 5em !important;
	color: red
   }
   .icon-lg {
	font-size: 50px
  }

 
.login-page{

    width: 100%;
    height: 100vh;
    display: inline-block;
    display: flex;
    align-items: center;
    background-size:cover
  }
 
  .input-row{
    margin-bottom:15px;
  }
  a {
    text-decoration: none;
  }
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }
  .logo-title{
    font: size 1.5em;
    font-style: italic;
    font-weight: 700;
  }
  .mobile-logo{
    display:none;
  }
  @media only screen and (max-width: 800px) {
    .mobile-logo{
      display:block;
    }
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    filter: invert(1);
    transform: scale(1.5);
}
.padding-bottom-200{
    padding-bottom: 100px;
}
.img-help{
    max-width:100%;
}
.hlp-button{
    font-size:16px !important;
    color:#0d6efd
}